import type { NodeDetail } from '~/types/api'

export function createNodesRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN = 'nodes'

  async function getDetail(id: number) {
    return get<NodeDetail>([DOMAIN, id.toString()])
  }

  return {
    getDetail,
  }
}
