export enum NodeTypes {
  CarouselSlide = 'carouselslide',
  ContactSalesman = 'contactsalesman',
  ContactSection = 'contactsection',
  ContentBlock = 'contentblock',
  ContentTag = 'contenttag',
  Country = 'country',
  Currency = 'currency',
  CustomerGroup = 'customergroup',
  Designer = 'designer',
  Surface = 'fabric',
  SurfaceCollection = 'fabriccollection',
  SurfaceCollectionCategory = 'fabriccollectioncategory',
  File = 'file',
  FileTag = 'filetag',
  ForMedia = 'formedia',
  ForMediaSection = 'formediasection',
  GroupModifier = 'groupmodifier',
  Job = 'job',
  News = 'news',
  Notification = 'notification',
  Offer = 'offer',
  OfferItem = 'offeritem',
  Page = 'page',
  Price = 'price',
  PriceCategory = 'pricecategory',
  Product = 'product',
  ProductAccessory = 'productaccessory',
  ProductAccessoryCategory = 'productaccessorycategory',
  ProductCategory = 'productcategory',
  ProductDescriptionItem = 'productdescriptionitem',
  ProductFilterTag = 'productfiltertag',
  ProductLine = 'productline',
  ProductProperty = 'productproperty',
  ProductPropertyType = 'productpropertytype',
  ProductSet = 'productset',
  Reference = 'reference',
  ReferenceCategory = 'referencecategory',
  Region = 'region',
  Rendering = 'rendering',
  Subscriber = 'subscriber',
  Video = 'video',
  Zone = 'zone',
}

export default NodeTypes
